<template>
    <div class="search-block">
        <div class="popcontext">
          <ul class="tab">
            <li >
              <b style="font-size:16px;">鸿鹄会</b>
            </li>
            <li rel="hgroup-userinfo"  :class="activeIndex=='userinfo'?'active':''" id="li_contact" @click="selectPannel('userinfo')">
              <span>个人中心</span>
            </li>
            <li
              rel="hgroup-orderlist"
              @click="selectPannel('orderlist')"
              :class="activeIndex=='orderlist'?'active':''"
              id="li_dynamic"
            >
              <span>订单管理</span>
            </li>
            <li rel="hgroup-memberHand"  :class="activeIndex=='memberHand'?'active':''" id="li_contact" @click="selectPannel('memberHand')">
              <span>实名认证</span>
            </li>
            <li rel="hgroup-changepwd"  :class="activeIndex=='changepwd'?'active':''" id="li_contact" @click="selectPannel('changepwd')">
              <span>修改密码</span>
            </li>
          </ul>
        </div>
      </div>
  </template>
  <script>
  export default {
    props:['activeIndex'],
    data() {
    return {
      input: ""
    };
   },
  methods: {
    selectPannel(pannelId) {
     // console.log(pannelId);
      this.activeIndex=pannelId;
     
      if(pannelId=="orderlist"){
        this.$router.push({path:'/member/orderlist',query: {t:1}})
      }
      else if(pannelId=='passenger'){
        this.$router.push({path:'/member/frequentflyer',query: {t:1}})
      }
      else if(pannelId=='userinfo'){
        this.$router.push({path:'/member/userinfo',query: {t:1}})
      }
      else if(pannelId=='changepwd'){
        this.$router.push({path:'/member/changepwd',query: {t:1}})
      }
      else if(pannelId=='memberHand'){
        this.$router.push({path:'/member/memberHand',query: {t:1}})
      }
    }
  }
  }
  </script>
  <style scoped>
    
.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}
.popcontext{
  width: 240px;
  background: #ffffff !important;
}
.popcontext .tab {
  z-index: 1000;
  width: 240px;
}
.popcontext .tab li {
  height: 45px;
  line-height: 45px;
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

/* border-bottom: 1px solid #d6003a; */

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}


.popcontext .tab li:nth-child(2).active {
  border-top: 1px solid #f9f9f9;
}
.popcontext .tab li.active {
  /* border-top: 1px solid #fff; */
  background-color: #d6003a;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}
.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}
.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}
  </style>